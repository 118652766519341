<template>
  <div>
    
    <el-dialog v-model="show_import" width="500px" append-to-body :show-close="false">
      <template #title>
        <div class="dialog_box">
          <div class="con_title"><span></span> 导入</div>
        </div>
      </template>
      <div>
          <el-upload
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            Authorization
            :headers="headers"
            class="upload-demo"
            ref="upload"
            :on-success="handleUploadSuccess"
            :action="url"
            :multiple="false"
            :limit="1"
            :on-change="change"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          </el-upload>
          <div style="padding-top: 10px;">{{title}}  <a :href="template" :download="temp_name" style="color: #409eff;">点击下载</a></div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :loading="is_ajax" @click="submitUpload">导入</el-button>
          <el-button @click="show_import = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'import',
  props: {
    
  },
  data() {
    return {
        show_import:false,
        fileList:[],
        is_ajax:false,
        type:'',  //1 客户供应商
        url:'',
        title:'',
        get_url:'',
        template:'',
        temp_name:'',
        headers: {
            'Authorization': window.sessionStorage.getItem("token"),
            'api-type':'web'
        },
        file_length:0
    }
  },
  methods: {
    init(type){
        this.fileList=[];
        this.show_import=true;
        this.is_ajax=false;
        this.file_length=0;
        this.type=type;
        if(type==1){
            this.get_url="/backend/Merchant/importTemplate";
            this.url="/backend/Merchant/import";
            this.title='客户供应商模板文件'
        }
        if(type==2){
            this.get_url="/backend/product.Product/importTemplate";
            this.url="/backend/product.Product/import";
            this.title='产品模板文件'
        }
        if(type==3){
            this.get_url="/backend/ProductStock/importTemplate";
            this.url="/backend/ProductStock/import";
            this.title='库存模板文件'
        }
        this.get_data()
    },
    handleUploadSuccess(res,file, fileList) {
      if(res.status==200){
        this.$message.success('导入成功');
        this.show_import=false;
        this.$emit("handleUploadSuccess", {});
      }else{
        this.is_ajax=false;
        this.fileList=[];
        this.file_length=0;
        this.$message.error(res.message);
      }
    },
    submitUpload() {
      if(this.file_length<1){
            this.$message.error('请选择文件');
            return false;
      }
      this.is_ajax=true;
      this.$refs.upload.submit();
    },
    change(file, fileList){
        this.file_length=fileList.length
    },
    handleRemove(file, fileList) {
      this.file_length=fileList.length
    },
    get_data(){
        this.$httpGet(this.get_url, {}).then((res) => {
            if (res.status == 200) {
                this.template=this.$http+'/'+res.data.file;
                let temp=res.data.file.split('/')
                this.temp_name=temp[temp.length-1];
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  }
}
</script>

<style scoped>

</style>
