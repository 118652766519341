<template>
  <div class="container_con">
    <el-card>
      <div class="search">
        <div>
          <el-button type="primary" @click="$router.push('/addProductList')" v-if="have_auth('/productList/add')">+
            新增</el-button>
          <!-- <el-button type="primary" icon="el-icon-s-ticket" :disabled="single" @click="handleMerge()"  v-if="have_auth('/productList/merge')">合并</el-button> -->
          <el-button type="primary" icon="el-icon-download" v-if="have_auth('/productList/import')"
            @click="try_import">导入</el-button>
          <el-button type="primary" icon="el-icon-upload2" v-if="have_auth('/productList/export')" :loading="is_export"
            @click="try_export">导出</el-button>
        </div>
        <div>
          <el-input icon="el-icon-search" v-model="queryParams.manufacturer" @keyup.enter.native="handleQuery"
            placeholder="请输入生产厂家" clearable style="width:300px;margin-right:10px"></el-input>
            <el-input icon="el-icon-search" v-model="queryParams.productNo" @keyup.enter.native="handleQuery"
            placeholder="请输入产品编号" clearable style="width:300px;margin-right:10px"></el-input>
          <el-input icon="el-icon-search" v-model="queryParams.product_no" @keyup.enter.native="handleQuery"
            placeholder="请输入产品编号/产品名称/助记码" clearable style="width:300px;margin-right:10px"></el-input>
          <el-button type="primary" @click="handleQuery()">搜索</el-button>
          <el-button @click="resetQuery()">重置</el-button>
        </div>
      </div>

      <div style="margin-top:20px">
        <el-table :data="list" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column prop="product_no" label="产品编号" />
          <el-table-column prop="product_name" label="品名" />
          <el-table-column prop="register_cert_name" label="注册证名称" />
          <el-table-column prop="manufacturer" label="生产厂家" />
          <el-table-column prop="register_cert_no" label="注册证号" />
          <el-table-column prop="register_cert_expire" label="注册证到期时间" />
          <el-table-column prop="model" label="型号"  width="60"/>
          <el-table-column prop="spec_value_name" label="规格" width="60" />
          <el-table-column fixed="right" label="操作" width="220">
            <template v-slot="scope">
              <el-button @click="handleDetail(scope.row)" type="text" v-if="have_auth('/productList/view')"
                size="small">查看</el-button>
              <el-button type="text" size="small" @click="handleUpdate(scope.row)"
                v-if="have_auth('/productList/edit')">编辑</el-button>
              <el-button type="text" size="small" @click="handleDelete(scope.row)"
                v-if="have_auth('/productList/del')">删除</el-button>
              <el-button type="text" size="small" v-if="have_auth('/productList/editNumber')" @click="handleEditeNumber(scope.row)">编辑产品编号</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

  </div>
  <pagination v-show="total > 0" :total="total" v-model:page="queryParams.page" v-model:limit="queryParams.size"
    @pagination="getList" />
  <importmodel ref="importmodel" @handleUploadSuccess="getList" />
</template>

<script>
import importmodel from "@/components/import";
export default {
  name: "list",
  components: {
    importmodel
  },
  data() {
    return {
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
        page: 1,
        size: 10,
        product_no: '',
        productNo:'',
        manufacturer:''
      },
      total: 0,
      list: [],
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      is_export: false,
      sysConfig: [],//产品前缀列表
      dialogVisible: false,
      form: {
        name: '',
        region: ''
      }
    };
  },
  created() {
    this.queryParams = window[this.$route.path] || {
      page: 1,
      size: 10,
      product_no: '',
      productNo:'',
      manufacturer:''
    }
    this.getList();
  },
  watch: {
    $route(to, form) {
      window[form.path] = this.queryParams
    },
  },
  methods: {
    resetQuery() {
      this.queryParams = {
        page: 1,
        size: 10,
        product_no: '',
        productNo:'',
        manufacturer:''
      }
      this.getList()
    },
    try_import() {
      this.$refs.importmodel.init(2)
    },
    try_export() {
      if (this.is_export) { return false; }
      this.is_export = true;
      this.$httpGet("/backend/product.Product/export", this.queryParams).then((res) => {
        if (res.status == 200) {
          var a = document.createElement('a');
          let name = res.data.file.split('/')
          var fileName = name[name.length - 1];
          a.download = fileName;
          a.href = this.$http + '/' + res.data.file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          this.$message.error(res.message);
        }
        this.is_export = false;
      }).catch((err) => {
        console.log(err);
        this.is_export = false;
      });
    },
    handleMerge() {

    },
    // 查看详情
    handleDetail(row) {
      this.$router.push('/productDetail?id=' + row.id)
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push('/addProductList?id=' + row.id)
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const _this = this;
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {

        _this.$httpGet("/backend/product.Product/del", { id: ids }).then((res) => {
          if (res.status == 200) {
            _this.getList();
            _this.$message.success(res.message);
          } else {
            _this.$message.error(res.message);
          }
        }).catch((err) => {
          console.log(err);
        });
      })
    },
    // 编辑产品编号
    handleEditeNumber(row) {
      this.$router.push('/addProductNumber?id=' + row.id)
    },
    
    /** 查询列表 */
    getList() {
      this.loading = true;
      this.$httpGet("/backend/product.Product/index", this.queryParams).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          this.list = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    }
  },
};
</script>

<style lang="scss" scoped></style>
